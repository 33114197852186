<template>
  <MoleculeEmpty
    icon="cloud-download"
    :title="$t('pages.presentation.download.title')"
  >
    <template #message>
      {{ $t('pages.presentation.download.description') }}
    </template>
    <div class="btn-list justify-content-center">
      <AtomButton to="/">
        <arrow-left-icon />{{ $t('action.back_home') }}
      </AtomButton>
      <AtomButton
        color="primary"
        :loading="loading"
        @click.prevent="download"
      >
        <cloud-download-icon />{{ $t('presentation.download') }}
      </AtomButton>
    </div>
  </MoleculeEmpty>
</template>

<script>
import { mapMutations } from 'vuex'
import { functions } from '@/firebase'
import MoleculeEmpty from '@/components/MoleculeEmpty'
import AtomButton from '@/components/AtomButton'

export default {
  title: vm => vm.$t('pages.presentation.download.title'),

  data: () => ({
    loading: false,
  }),

  components: {
    MoleculeEmpty,
    AtomButton,
  },

  async mounted () {
    this.download()
  },

  methods: {
    ...mapMutations(['setError']),

    async download () {
      try {
        this.loading = true
        const numbers = this.$route.query.numbers.split(',')
        const downloadPresentation = functions.httpsCallable('presentation-download')
        const response = await downloadPresentation(numbers)
        const [url] = response.data.url

        const link = document.createElement('a')
        link.style.display = 'none'
        link.setAttribute('target', '_blank')
        link.setAttribute('href', url)
        link.setAttribute('download', true)
        document.body.appendChild(link)
        link.click()
        link.remove()
      } catch (e) {
        this.setError(e)
      } finally {
        this.loading = false
      }
    }
  },
}
</script>
